import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const SearchBanner = () => {
  const [searchQuery, setSearchQuery] = useState({
    query: "",
    search_type: "address",
  });
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate(
      `/search-property?search_query=${searchQuery?.query}&&search_type=${searchQuery?.search_type}`
    );
  };

  const handleChange = (event) => {
    setSearchQuery({ ...searchQuery, [event.target.name]: event.target.value });
  };

  return (
    <>
      <section className="property_listing_search">
        <div className="contain">
          <h3>Search properties</h3>
          <div className="hide_cell_link_advance">
            <Link to="/search-property">Advanced Property Search</Link>
          </div>
          <div className="search_relative">
            <form onSubmit={handleSubmit}>
              <select
                name="search_type"
                className="input"
                onChange={handleChange}
              >
                <option value="address">Address</option>
                <option value="city_state">City, State</option>
                <option value="zipcode">Zipcode</option>
                <option value="owner_name">Property Owner</option>
              </select>
              <input
                type="text"
                placeholder="Search by address, location, or owner"
                className="input"
                defaultValue={searchQuery.query}
                name="query"
                onChange={handleChange}
                required
              />
              {/* <Link
                to={"/search-property?search_query=" + searchQuery}
                className="site_btn only_search_icon search_by_map"
              >
                <span className="hide_sm_src">Search by Map</span>{" "}
                <span className="hide_big_src">
                  <img src="/images/search_green.svg" alt="" />
                </span>
              </Link> */}
              <button type="submit" className="site_btn only_search_icon">
                <span className="hide_sm_src">Search</span>{" "}
                <span className="hide_big_src">
                  <img src="/images/search_green.svg" alt="" />
                </span>
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchBanner;
