import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateMEmberCredits } from "../../../states/actions/fetchSiteSettings";
import Text from "../../common/Text";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import http from "../../../helpers/http";
import { doObjToFormData } from "../../../helpers/helpers";
import { toast } from "react-toastify";
import ContactInfoPopup from "./contact_info";
export default function TableDataBlk({ val, i }) {
  const [isTypeLoading, setIsTypeLoading] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(null);
  const dispatch = useDispatch();
  const ToggleContact = (id) => {
    if (showContactInfo === null) {
      setIsTypeLoading(true);
      http
        .post(
          `request-property-contact/${id}`,
          doObjToFormData({ id_type: "" })
        )
        .then(({ data }) => {
          setIsTypeLoading(false);
          if (data?.status === 1) {
            if(data?.cached_data!==1){
                          dispatch(updateMEmberCredits(data?.total_available_credits));
                        }
            setShowContactInfo({
              email: data?.email,
              phone: data?.phone,
            });
          } else {
            toast.error(<Text string={data?.msg} />, TOAST_SETTINGS);
          }
        })
        .catch((error) => {
          setIsTypeLoading(false);
        });
    }
  };
  useEffect(() => {
        if(val?.email?.length>0 || val?.phone?.length>0){
          setShowContactInfo({email: val?.email,phone: val?.phone,})
        }
      }, [val]);
  return (
    <>
      <tr key={i + 5612345 + 12789100000000}>
        <td>{val.address}</td>
        <td>{val.last_sale}</td>
        <td>${Number(val.sale_price).toLocaleString()}</td>
        <td>{val.year_built}</td>
        <td>{Number(val.area).toLocaleString()} sqft</td>
        <td>{val.units}</td>
        <td>{val.type}</td>
        <td>
          {showContactInfo?.email?.length > 0 ||  showContactInfo?.phone?.length > 0 ? (
            <ul className="social new_socials_tab">
              {
                showContactInfo?.phone?.length > 0 ?
              <li>
                <ContactInfoPopup contact_arr={showContactInfo.phone} heading="Available Phone Numbers:" />
              </li>
              :
              ""
}
              {
                showContactInfo?.email ?
              <li>
                <ContactInfoPopup contact_arr={showContactInfo.email} heading="Available Emails:" />
              </li>
              :
              ""
}
            </ul>
          ) : (
            <a
              href="#!"
              className="site_btn sm"
              onClick={() => ToggleContact(val?.encoded_parcel_id)}
              disabled={isTypeLoading}
            >
              View Contact {isTypeLoading ? <i className="spinner"></i> : ""}
            </a>
          )}
        </td>
      </tr>
    </>
  );
}
