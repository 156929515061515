import React, { useState } from "react";

export default function ContactInfoPopup({contact_arr,heading,is_icon=false,type=null}) {
    const [isOpen, setIsOpen] = useState(false);
    
  return <>
  <div className="phone_email_flex flex">
  {contact_arr?.[0] && (
        <a>
            { is_icon ? <img src={type==='phone' ? "/images/call-icon.svg" : "/images/email-icon.svg"} alt="" />  : ""}
            {contact_arr[0]}
        </a>
    )}
    {contact_arr?.length > 1 && (
        <>
            <span onClick={()=>setIsOpen(true)}>+{contact_arr?.length - 1} more</span>
        </>
     )}
  </div>
     {
        isOpen ?
        <div class="popup sm popup_password_change active contact_popup custom_contact_pop">
        <div className="table_dv">
            <div className="table_cell">
                <div className="contain">
                    <div className="_inner">
                        <button
                            type="button"
                            className="x_btn"
                            onClick={()=>setIsOpen(false)}
                        ></button>
                        <div>
                            <h5>{heading}</h5>
                            <ul>
                                {
                                contact_arr?.map((contact_phone,index)=>{
                                    return(
                                    <li key={index}>{contact_phone}</li>
                                    )
                                })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    :
    ""
     }
    
  </>;
}
