import React, { useEffect } from 'react';

const GoogleAnalyticsCode = ({ scripts }) => {
  useEffect(() => {
    // Create a temporary div to hold the script tags and inject them
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = scripts;

    // Keep track of the dynamically added scripts
    const addedScripts = [];

    // Append the script tags to the document body
    const scriptTags = tempDiv.querySelectorAll('script');
    scriptTags.forEach((script) => {
      const newScript = document.createElement('script');
      newScript.src = script.src || '';
      newScript.innerHTML = script.innerHTML || '';
      if (script.src) {
        newScript.onload = () => {
          console.log(`${script.src} loaded successfully`);
        };
      }
      document.body.appendChild(newScript);
      addedScripts.push(newScript); // Keep track of added script
    });

    // Clean up the scripts when the component is unmounted
    return () => {
      addedScripts.forEach((script) => {
        if (script && document.body.contains(script)) {
          document.body.removeChild(script);
        }
      });
    };
  }, [scripts]);

  return null;
};

export default GoogleAnalyticsCode;
