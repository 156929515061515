import React, { useState } from "react";
import Filters from "./Filters";
import ResultFilters from "./Result-filter";
import TableData from "./Table-data";
import MobileAdvance from "./Mobile-advance";

const SearchTable = () => {
  const [hideSearch, setHideSearch] = useState(false);
  const ToggleHideSearch = () => {
    setHideSearch(!hideSearch);
  };
  const searches = {
    data: [
      {
        id: "1",
        address: "Park St, Atlanta, TX 75551",
        last_sale: "30 Dec, 2019",
        sale_price: "$1,250,300",
        year_built: "2018",
        area: "6,070 SF",
        units: "--",
        type: "Multifamily (General)",
        email: "/",
        call: "/"
      },
      {
        id: "2",
        address: "Park St, Atlanta, TX 75551",
        last_sale: "--",
        sale_price: "--",
        year_built: "2018",
        area: "6,070 SF",
        units: "--",
        type: "Multifamily (General)",
        email: "/",
        call: "/"
      },
      {
        id: "3",
        address: "Park St, Atlanta, TX 75551",
        last_sale: "30 Dec, 2019",
        sale_price: "$1,250,300",
        year_built: "--",
        area: "6,070 SF",
        units: "--",
        type: "Multifamily (General)",
        email: "/",
        call: "/"
      },
      {
        id: "4",
        address: "Park St, Atlanta, TX 75551",
        last_sale: "--",
        sale_price: "$1,250,300",
        year_built: "--",
        area: "6,070 SF",
        units: "02",
        type: "Multifamily (General)",
        email: "/",
        call: "/"
      },
      {
        id: "5",
        address: "Park St, Atlanta, TX 75551",
        last_sale: "--",
        sale_price: "$1,250,300",
        year_built: "--",
        area: "6,070 SF",
        units: "--",
        type: "Multifamily (General)",
        email: "/",
        call: "/"
      },
      {
        id: "6",
        address: "Park St, Atlanta, TX 75551",
        last_sale: "30 Dec, 2019",
        sale_price: "--",
        year_built: "2018",
        area: "6,070 SF",
        units: "02",
        type: "Multifamily (General)",
        email: "/",
        call: "/"
      },
      {
        id: "7",
        address: "Park St, Atlanta, TX 75551",
        last_sale: "30 Dec, 2019",
        sale_price: "$1,250,300",
        year_built: "2018",
        area: "6,070 SF",
        units: "--",
        type: "Multifamily (General)",
        email: "/",
        call: "/"
      },
      {
        id: "8",
        address: "Park St, Atlanta, TX 75551",
        last_sale: "30 Dec, 2019",
        sale_price: "$1,250,300",
        year_built: "2018",
        area: "6,070 SF",
        units: "--",
        type: "Multifamily (General)",
        email: "/",
        call: "/"
      }
    ]
  };
  return (
    <>
      <MobileAdvance />
      <Filters ToggleHideSearch={ToggleHideSearch} hideSearch={hideSearch} />
      <ResultFilters
        ToggleHideSearch={ToggleHideSearch}
        hideSearch={hideSearch}
      />
      <TableData data={searches.data} hideSearch={hideSearch} />
    </>
  );
};

export default SearchTable;
