import React, { useState } from "react";
import http from "../../../helpers/http";
import { toast } from "react-toastify";

export default function DeleteLabel({label_id,setUserLabels,setEditLAbel}) {
    const [isDeleting, setIsDeleting] = useState(false);
    
const deleteLabel=(e)=>{
    e.preventDefault()
    if (label_id) {
        setIsDeleting(true)
        http
                  .post("delete-user-label-properties/" + label_id, {label_id:label_id})
                  .then(({ data }) => {
                    setIsDeleting(false)
                    toast.success(data.msg);
                    if (data.status == 1) {
                      setUserLabels(data?.labels);
                      setEditLAbel({ show: false, item: null });
                    }
                  })
                  .catch((error) => { setIsDeleting(false);console.log(error);
                  });
    }
}
  return <>
        <button onClick={deleteLabel}
            className="site_btn blank border" type="button" disabled={isDeleting}>Delete {isDeleting ? <i className="spinner"></i> : ''}</button>
  </>;
}
