import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Filters from "../search/Filters-search";
import OwnerFilter from "./Owner-filter";
import PropertyChart from "./Property-chart";
import MobileAdvance from "../search/Mobile-advance";
import http from "../../../helpers/http";
import LoadingScreen from "../../common/LoadingScreen";
import { abbreviatedAmount } from "../../../helpers/helpers";

const fetchOwnerOverview = async (
  ownerEmail,
  setOwnerData,
  setStats,
  setLoading
) => {
  setLoading(true);
  http
    .post("/getowneroverview", ownerEmail)
    .then(({ data }) => {
      setOwnerData(data.owner);
      setStats(data.stats);
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
    });
};

const OwnerOverview = () => {
  const [hideSearch, setHideSearch] = useState(false);
  const { ownerEmail } = useParams();
  const [ownerData, setOwnerData] = useState("");
  const [stats, setStats] = useState("");
  const [isLoading, setLoading] = useState(true);

  const ToggleHideSearch = () => {
    setHideSearch(!hideSearch);
  };

  useEffect(() => {
    fetchOwnerOverview(ownerEmail, setOwnerData, setStats, setLoading);
  }, [ownerEmail]);
  return (
    <>
      <MobileAdvance />
      <Filters ToggleHideSearch={ToggleHideSearch} hideSearch={hideSearch} />
      <OwnerFilter
        ownerData={ownerData}
        ownerEmail={ownerEmail}
        ToggleHideSearch={ToggleHideSearch}
        hideSearch={hideSearch}
      />
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <section
          className={
            hideSearch
              ? "table_view_sec companies_table_page owner_overview_page"
              : "table_view_sec companies_table_page owner_overview_page with_filter_sec"
          }
        >
          <div className="contain-fluid">
            <h4 className="main_heading">Properties Overview</h4>
            <div className="chart_flex flex">
              <div className="col">
                <div className="inner">
                  <p>Property Type</p>
                  <h4>By Property Count</h4>
                  <div className="mid_text">
                    <span>
                      {stats.totalProperties > 0 && stats.totalProperties}
                    </span>{" "}
                    {stats.totalProperties ? "Propertie(s)" : "N/A"}
                  </div>
                  <div>
                    <PropertyChart
                      labels={stats.propertiesLabels}
                      datasets={stats.propertiesDatasets}
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="inner">
                  <p>Property Type</p>
                  <h4>By Assessed Value</h4>
                  <div className="mid_text">
                    <span>
                      {stats.totalPrice > 0 &&
                        "$" + abbreviatedAmount(stats.totalPrice)}
                    </span>{" "}
                    {stats.totalPrice > 0 ? `Assessed Value` : ""}
                  </div>
                  <div>
                    <PropertyChart
                      labels={stats.priceLabels}
                      datasets={stats.priceDatasets}
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="inner">
                  <p>Property Type</p>
                  <h4>By Building Area</h4>
                  <div className="mid_text">
                    <span>
                      {stats.totalBuilingArea > 0 &&
                        abbreviatedAmount(stats.totalBuilingArea)}
                    </span>{" "}
                    {stats.totalBuilingArea > 0 ? "Square Feet" : "N/A"}
                  </div>
                  <div>
                    <PropertyChart
                      labels={stats.buildingAreaLabels}
                      datasets={stats.buildingAreaDatasets}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default OwnerOverview;
