import React from "react";
import Logo from "./Logo";
import { Link, NavLink } from "react-router-dom";
import SocialLinks from "./SocialLinks";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function Footer() {
  const location = useLocation();
  const location_path = location?.pathname;
  const current_page = location_path.split("/")[1];

  const site_settings = useSelector(
    (state) => state.fetchSiteSettings.site_settings
  );
  const industries = site_settings?.industries
  return (
    <>
      {current_page == "login" ||
        current_page == "signup" ||
        current_page == "enterprise-checkout" ||
        current_page == "checkout" ? (
        ""
      ) : (
        <footer>
          <div className="contain">
            <div className="flex">
              <div className="col">
                <div className="f_logo">
                  <Logo site_settings={site_settings} />
                </div>
              </div>
              <div className="col">
                <h5>Services</h5>
                <ul className="lst">
                  {industries?.map((industry, index) => {
                    return (
                      <li key={industry?.id + "-" + index}>
                        <a
                          href={"/industry/" + industry?.slug}
                        >{industry?.title}</a>
                      </li>
                    );
                  })}
                </ul>
                <div className="br" />
                <h5>LEARN THE RHINO WAY</h5>
                <ul className="lst">
                  <li>
                    <Link to="/">Watch Tutorial</Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <h5>TERMS &amp; PRIVACY</h5>
                <ul className="lst">
                  <li>
                    <Link to="/terms-conditions">Terms of Service</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/subscription-agreement">Subscription Agreement</Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <h5>ABOUT</h5>
                <ul className="lst">
                  {/* <li>
                    <Link to="/about">About</Link>
                  </li> */}
                  <li>
                    <Link to="/support">Support</Link>
                  </li>
                </ul>
                <div className="br" />
                {/* <h5>RESOURCES</h5>
                <ul className="lst">
                  <li>
                    <Link to="/blogs">Blog</Link>
                  </li>
                  <li>
                    <Link to="/help">Help Center</Link>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
          <div className="copy_right">
            <div className="contain">
              <div className="_inner">
                <p>{site_settings?.site_copyright}</p>
                <SocialLinks site_settings={site_settings} />
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
}

export default Footer;
