import React, { useState, useEffect } from "react";
import Filters from "./Filters-search";
import ResultFilters from "./Result-filter";
import ResultProperties from "./Result-properties";
import Ownersresults from "./Owners-filter";
import MobileAdvance from "./Mobile-advance";

const ResultSearch = (data) => {
  const hideSearch = data?.hideSearch;
  const ToggleHideSearch = data?.ToggleHideSearch;
  const searchedPage = data?.searchedPage;
  const sortValue = data?.sortValue;
  const setSortValue = data?.setSortValue;
  const [listingData, setListingData] = useState(null);
  const [showowners, setShowowners] = useState(true);
  const [searchCheckboxes, setSearchCheckboxes] = useState(false);
  const [selectedPropertyIds, setSelectedPropertyIds] = useState([]);
  const [newAddedPropertyLabels, setNewAddedPropertyLabels] = useState([]);
  const ownertoggle = () => {
    setShowowners(!showowners);
  };

  useEffect(() => {
    setListingData([]);
    if (data.data) {
      setListingData(JSON.parse(data.data));
    }
  }, [data.data]);
  const getPropertyTitles = (propertyData, selectedPropertyIds) => {
    if (propertyData?.length > 0) {
      const propertyTitles = propertyData
        ?.filter((property) => selectedPropertyIds.includes(property.parcel_id))
        .map((property) => property.title);
      return propertyTitles;
    }
  };
  const getPropertyParcelIds = (propertyData, selectedPropertyIds) => {
    if (propertyData?.length > 0) {
      const propertyParcelIDs = propertyData
        ?.filter((property) => selectedPropertyIds.includes(property.parcel_id))
        .map((property) => property.parcel_id);
      return propertyParcelIDs;
    }
  };

  const selectedPropertyTitles = getPropertyTitles(
    listingData?.properties,
    selectedPropertyIds
  );
  const selectedPropertyParcelIDs = getPropertyParcelIds(
    listingData?.properties,
    selectedPropertyIds
  );
  // const [hideSearch, setHideSearch] = useState(false);
  // const ToggleHideSearch = () => {
  //   setHideSearch(!hideSearch);
  // };
  const handleSearchSortSubmit = (e, value) => {
    data?.handleSubmit(e, "search_field", value);
  };

  return (
    <>
      {/* <MobileAdvance />
      <Filters
        ToggleHideSearch={ToggleHideSearch}
        hideSearch={hideSearch}
        ownertoggle={ownertoggle}
        showowners={showowners}
      /> */}
      {/* <h1></h1> */}
      <ResultFilters
        showowners={showowners}
        ownertoggle={ownertoggle}
        ToggleHideSearch={ToggleHideSearch}
        hideSearch={hideSearch}
        toggleResultView={data.toggleResultView}
        resultView={data.resultView}
        sortValue={sortValue}
        setSortValue={setSortValue}
        handleSearchSortSubmit={handleSearchSortSubmit}
        selectedPropertyIds={selectedPropertyIds}
        setSearchCheckboxes={setSearchCheckboxes}
        searchCheckboxes={searchCheckboxes}
        selectedPropertyTitles={selectedPropertyTitles}
        setNewAddedPropertyLabels={setNewAddedPropertyLabels}
        newAddedPropertyLabels={newAddedPropertyLabels}
        selectedPropertyParcelIDs={selectedPropertyParcelIDs}
      />
      {listingData && !showowners && (
        <Ownersresults
          data={listingData}
          showowners={showowners}
          ownertoggle={ownertoggle}
          ToggleHideSearch={ToggleHideSearch}
          hideSearch={hideSearch}
        />
      )}
      {listingData && showowners && (
        <ResultProperties
          data={listingData}
          handlePageClick={data.handlePageClick}
          hideSearch={hideSearch}
          searchedPage={searchedPage}
          drawnFeatures={data.drawnFeatures}
          setDrawnFeatures={data.setDrawnFeatures}
          setSelectedPropertyIds={setSelectedPropertyIds}
          selectedPropertyIds={selectedPropertyIds}
          searchCheckboxes={searchCheckboxes}
          newAddedPropertyLabels={newAddedPropertyLabels}
          setShowowners={setShowowners}
          setListingData={setListingData}
          Maptoggle={data.Maptoggle}
          setSearchQueryValues={data.setSearchQueryValues}
          handleSubmit={data.handleSubmit}
          propertyTypeExists={data?.propertyTypeExists}
        />
      )}
    </>
  );
};

export default ResultSearch;
