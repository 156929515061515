// reducers/searchQueryReducer.js
const initialState = {
    searchQueryValues: null, // Default state
  };
  
  const searchQueryReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_SEARCH_QUERY":
        return { ...state, searchQueryValues: action.payload }; // Update the state
      default:
        return state; // Return the current state for unhandled actions
    }
  };
  
  export default searchQueryReducer;
  