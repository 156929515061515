import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
import http from "../../../helpers/http";
import { doObjToFormData } from "../../../helpers/helpers";
import { updateMEmberCredits,updateContactInfo } from "../../../states/actions/fetchSiteSettings";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import Text from "../../common/Text";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ContactInfoPopup from "./contact_info";

export default function PropertyTableViewBlk({val,handleCheckboxChange,displayedColumns,handleToggleForLabel,handleLikeClick,i}) {
  const [isTypeLoading, setIsTypeLoading] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(null);
const dispatch=useDispatch()
  const handleRequestMailOrTel = (e, type) => {
    e.preventDefault();
    setIsTypeLoading(true);
    http
      .post(
        `request-property-contact/${val?.id}`,
        doObjToFormData({ type: type })
      )
      .then(({ data }) => {
        setIsTypeLoading(false);
        if (data?.status === 1) {
         if(data?.cached_data!==1){
                       dispatch(updateMEmberCredits(data?.total_available_credits));
                     }
          setShowContactInfo({
            email: data?.email,
            phone: data?.phone,
          });
          const contact_info={
            id:val?.id,
            email: data?.email,
            phone: data?.phone,
          }
          dispatch(updateContactInfo(contact_info))
        } else {
          toast.error(<Text string={data?.msg} />, TOAST_SETTINGS);
        }
      })
      .catch((error) => {
        setIsTypeLoading(false);
      });
  };
  useEffect(() => {
      if(val?.email?.length>0 || val?.phone?.length>0){
        setShowContactInfo({email: val?.email,phone: val?.phone,})
      }
    }, [val]);
  return <>
  <div className="flex" key={i + 98723456 + 12789100000000}>
                    <div className="col_all col_1">
                      <div className="flex">
                        {/* <div className="sm_icon"> */}
                        <input
                          type="checkbox"
                          name="owners[]"
                          checked={val?.checked}
                          onChange={(e) => handleCheckboxChange(e, i)}
                        />
                        {/* <img src="/images/chart-tree.svg" alt="" /> */}
                        {/* </div> */}
                        <div className="_cntnt">
                          <h4>
                            <Link
                              to={`/property-details/${val.id}`}
                              target="_blank"
                            >
                              {val.title}
                            </Link>
                          </h4>
                        </div>
                      </div>
                    </div>
                    {displayedColumns?.includes('date') && (
                      <div className="col_all">
                        <small>Last Sale</small>
                        <p>{val.date}</p>
                      </div>
                    )}
                    {displayedColumns?.includes('price') && (
                      <div className="col_all">
                        <small>Sales Price</small>
                        <p>${Number(val.price).toLocaleString()}</p>
                      </div>
                    )}
                    {displayedColumns?.includes('build') && (
                      <div className="col_all">
                        <small>Year Built</small>
                        <p>{val.build}</p>
                      </div>
                    )}
                    {displayedColumns?.includes('square') && (
                      <div className="col_all">
                        <small>Building Area</small>
                        <p>{Number(val.square).toLocaleString()} SF</p>
                      </div>
                    )}
                    {displayedColumns?.includes('arealotsf') && (
                      <div className="col_all">
                        <small>Lot Size</small>
                        <p>{Number(val?.lot_size).toLocaleString()} AC</p>
                      </div>
                    )}
                    {displayedColumns?.includes('property_type') && (
                      <div className="col_all">
                        <small>Property Type</small>
                        <p>{val.property_type}</p>
                      </div>
                    )}
                    {displayedColumns?.includes('zonedcodelocal') && (
                      <div className="col_all">
                        <small>Zoning</small>
                        <p>{val.zonedcodelocal}</p>
                      </div>
                    )}
                    {displayedColumns?.includes('mortgage1amount') && (
                      <div className="col_all">
                        <small>Mortgage Amount</small>
                        <p>{val.mortgage1amount ? "$"+val.mortgage1amount : "$0"}</p>
                      </div>
                    )}
                    {displayedColumns?.includes('taxyearassessed') && (
                      <div className="col_all">
                        <small>Tax Year</small>
                        <p>{val.taxyearassessed}</p>
                      </div>
                    )}
                    {displayedColumns?.includes('taxassessedvaluetotal') && (
                      <div className="col_all">
                        <small>Tax Price</small>
                        <p>${Number(val.taxassessedvaluetotal).toLocaleString()}</p>
                      </div>
                    )}
                    {displayedColumns?.includes('owner') && (
                      <div className="col_all">
                        <small>Reported Owner</small>
                        <p>{val.owner}</p>
                      </div>
                    )}
                    {displayedColumns?.includes('contact') ? (
                      <div className="col_all contact_info">
                        <small>Contact</small>
                        <div className="contact_links table_contact_links">
                        {showContactInfo?.email?.length > 0 ||  showContactInfo?.phone?.length > 0  ? (
                            <>
                                <ContactInfoPopup contact_arr={showContactInfo.email} heading="Available Emails:" />
                                <ContactInfoPopup contact_arr={showContactInfo.phone} heading="Available Phone Numbers:" />
                            </>
                         ) : (
                            <div className="contact_info relative">
                                <div className="dp">
                                <img src="/images/profile.svg" alt="" />
                                </div>
                                <div className="cnt">
                                <h5>
                                    <a
                                    href="#!"
                                    onClick={(e) => handleRequestMailOrTel(e, "call")}
                                    disabled={isTypeLoading}
                                    >
                                    <div className="p_contact_wrap">
                                        {isTypeLoading
                                        ? "Please wait!"
                                        : "View"}
                                    </div>
                                    </a>
                                </h5>
                                </div>
                                {isTypeLoading ? (
                                <div class="loadingio-spinner-eclipse-spin">
                                    <div class="load-spinner">
                                    <div></div>
                                    </div>
                                </div>
                                ) : (
                                ""
                                )}
                            </div>
                            )}
                          
                        </div>
                      </div>
                    ) : null}
                    <div className="col_all">
                      <small>Actions</small>
                      <div className="cta_opt_lbl">
                      <ReactTooltip
            id="label-properties"
            place="bottom"
            content="Label Property"
          />
                        <a
                          href="javascript:void(0)"
                          onClick={() => handleToggleForLabel(val.id)}
                          data-tooltip-id="label-properties"
                        >
                          <img src="/images/ticket_star.svg" alt="" />
                        </a>
                        <ReactTooltip
            id="open-new-tab"
            place="bottom"
            content="View Property"
          />
                        <Link
                          to={`/property-details/${val.id}`}
                          target="_blank"
                          data-tooltip-id="open-new-tab"
                        >
                          <img src="/images/maximize.svg" alt="" />
                        </Link>
                        <ReactTooltip
            id="add-favorite"
            place="bottom"
            content="Add To Favorites"
          />
                        <a
                          href="javascript:void(0)"
                          style={
                            val.is_wish == 1 ? { background: "#67773f" } : {}
                          }
                          onClick={
                            val.is_wish
                              ? null
                              : () => handleLikeClick(val.id, dispatch)
                          }
                          data-tooltip-id="add-favorite"
                        >
                          <img
                            style={
                              val.is_wish == 1
                                ? { filter: "brightness(0) invert(1)" }
                                : {}
                            }
                            src="/images/heart_ico.svg"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
  </>;
}
