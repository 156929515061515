import React, { useState } from "react";
import { Link } from "react-router-dom";
import Filters from "./Filters";
import filterssearch from "./Filters-search";
import ResultFilters from "./Result-filter";
import MobileAdvance from "./Mobile-advance";
import { components } from "react-select";
import OwnerPropertiesListPopup from "../../../states/actions/OwnerPropertiesListPopup";

const OwnersFilter = (props, showSearchResultsOwners) => {
  const { data } = props;
  const { showowners, ownertoggle } = props;
  const [hideSearch, setHideSearch] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);

  const toggleHideSearch = () => {
    setHideSearch(!hideSearch);
  };

  const openPopup = (propertyId) => {
    setSelectedPropertyId(propertyId);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  const ToggleHideSearch = () => {
    setHideSearch(!hideSearch);
    console.log(hideSearch);
  };
  console.log(data?.owners);
  return (
    <>
      <section
        className={
          hideSearch
            ? "search_result_page owner_filter_sec owner_filter_tbl"
            : "search_result_page filter_show_after owner_filter_sec owner_filter_tbl"
        }
      >
        <div className="contain-fluid big_contain_fluid">
          <div className="outer_table">
            <div className="highlight_div">
              <h5>{data.owners.length} Owners</h5>
            </div>
            {data.owners.map((owner, index) => (
              <div className="flex" key={owner.email + "-" + 12789100000000}>
                <div className="col_all col_1">
                  <div className="flex">
                    <div className="sm_icon">
                      <img src="/images/chart-tree.svg" alt="" />
                    </div>
                    <div className="_cntnt">
                      <h4>
                        {owner.owner_name ? (
                          owner.link ? (
                            <Link to={`/owner-property/${owner.link}`}>
                              {owner.owner_name}
                            </Link>
                          ) : (
                            owner.owner_name
                          )
                        ) : (
                          "N/A"
                        )}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col_all">
                  <small>Properties In Search</small>
                  <p>{owner.property_count}</p>
                </div>
                <div className="col_all">
                  <small>Properties In Portfolio</small>
                  <p>
                    {owner.email !== ""
                      ? owner.propertyCount
                      : parseInt(owner.property_count) > 0
                      ? owner.property_count
                      : 0}
                  </p>
                </div>
                <div className="col_all">
                  <small>Portfolio Assessed Value</small>
                  <p>${Number(owner.total_price)?.toLocaleString()}</p>
                </div>
                <div className="col_all">
                  <small>Last Acquisition Date</small>
                  <p>{owner.assessorlastsaledate}</p>
                </div>
                <div className="col_all">
                  <small>Owner Location</small>
                  <p>{owner.state}</p>
                </div>
                <div className="col_all">
                  <small>Owner Status</small>
                  <p>{owner?.status}</p>
                </div>
                <div className="col_all">
                  {owner.email !== "" && (
                    <button
                      className="site_btn blank"
                      onClick={() => openPopup(owner.email)}
                    >
                      View {owner.propertyCount} Properties
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {popupVisible && (
        <OwnerPropertiesListPopup
          propertyId={selectedPropertyId}
          closePopup={closePopup}
          {...props}
        />
      )}
    </>
  );
};

export default OwnersFilter;
