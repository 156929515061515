import bulletIcon from "../../../assets/images/bullet-icon.png";
import linkIcon from "../../../assets/images/link-icon.png";
import { useState, useEffect } from "react";
import http from "../../../helpers/http";
import { Link } from "react-router-dom";
import { m } from "framer-motion";

const PopupContent = ({ feature }) => {
  const [encodedParcelNumber, setEncodedParcelNumber] = useState(null);
  const [address, setAddress] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (feature) {
      const parcelnumb = feature.properties.parcelnumb;
      const parcel_formatted = parcelnumb.replace(/[^0-9]/g, "");
      


      http
        .post(
          "/createencodedparcelid",
          {
            parcel_geom: JSON.stringify(feature.geometry),
          },
          {}
        )
        .then((res) => {
          if (res.data.status === 0) {
            setEncodedParcelNumber(-1);
            return;
          }
          setEncodedParcelNumber(res.data.encoded_parcel_id);
          setAddress(res.data.full_address);
        })
        .catch((err) => {
          console.log(err);
          setEncodedParcelNumber(-1);
        });
    }
  }, [feature]);

  console.log("features", feature);
  const { properties: data } = feature;

  return (
    <div style={{padding: 10, minWidth: 200}}>
      <div>Full Address:</div>
      <p style={styles.info}>{address ? address : '...'}</p>

      <div style={styles.linkContainer}>
        <div style={styles.link}>
          {encodedParcelNumber ? (
            encodedParcelNumber === -1 ? (
              "Link not found"
            ) : (
              <Link
                style={styles.link}
                to={`/property-details/${encodedParcelNumber}/?previousUrl=${encodeURIComponent(
                  urlParams.toString()
                )}`}
              >
                Property Details
              </Link>
            )
          ) : (
            "Generating Link..."
          )}
        </div>
      </div>
    </div>
  );
};

export default PopupContent;

const styles = {
  title: {
    fontWeight: 700,
    fontSize: 20,
    color: "#121900",
  },
  info: {
    fontWeight: 500,
    fontSize: 14,
    color: "black",
    opacity: 0.5,
    margin: 0,
  },
  row: {
    display: "flex",
    gap: 20,
    alignItems: "center",
  },

  link: {
    fontWeight: 500,
    fontSize: 15,
    color: "white",
  },

  linkContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
    backgroundColor: '#67773f',
    width: '100%',
    padding: 10,
    marginTop: 10,
    color: 'white',
  }
};
