import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Search from "./Search";

import {
  fetchHelpDetail,
  searchHelpResults
} from "../../../states/actions/fetchHelpDetail";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import Text from "../../common/Text";
import { timeAgo, getWordInitials } from "../../../helpers/helpers";
import FetchingStatus from "../../common/FetchingStatus";
import NoRecordFound from "../../common/NoRecordFound";

const SearchFound = () => {
  const dispatch = useDispatch();
  const { subcat_id } = useParams();
  const data = useSelector((state) => state.fetchHelpDetail.content);
  const isLoading = useSelector((state) => state.fetchHelpDetail.isLoading);
  const isSearching = useSelector((state) => state.fetchHelpDetail.isSearching);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const records = useSelector((state) => state.fetchHelpDetail.records);
  const { content, subcat } = data;

  useEffect(() => {
    dispatch(fetchHelpDetail({ subcat_id }));
  }, []);

  const handleSearch = (formData) => {
    formData = { ...formData, subcat_id: subcat_id };
    dispatch(searchHelpResults(formData));
  };

  useDocumentTitle(data.page_title);

  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Search content={content} handleSearch={handleSearch} />
          <section className="ans_blk">
            <div className="contain">
              <div className="main_title">
                <p>
                  {content.search_result_for}: <span>{subcat.name}</span>
                </p>
              </div>
              <ul className="ques_lst">
                {isSearching ? (
                  <FetchingStatus />
                ) : records.length > 0 ? (
                  records?.map((row, i) => {
                    return (
                      <li key={i + 6789543100000000}>
                        {/* <Link to={`/question-article/${row.encoded_id}`}></Link> */}
                        <h5>{row.question}</h5>
                        <p><Text string={row.answer} /></p>
                        <br />
                        <div className="auther_lbl">
                          <div className="auther_icon">
                            <span>{getWordInitials(row.written_by)}</span>
                          </div>
                          <div className="auther_cntnt">
                            <h6>
                              Written by <span>{row.written_by}</span>
                            </h6>
                            {/* <p>{timeAgo(row.created_at)}</p> */}
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <NoRecordFound />
                )}
              </ul>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default SearchFound;
